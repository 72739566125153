<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin phiếu thu cũ'">
          <template v-slot:preview>
            <b-row class="mb-5">
              <!-- LEFT INPUT CONTROLS -->
              <b-col lg="9" md="9" sm="12">
                <b-row>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Ngày nhập kho:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <date-picker
                        placeholder="Chọn ngày nhập kho"
                        class="mb-2 mt-2 form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="$v.form.dateImport.$model"
                        :state="validateState('dateImport')"
                        aria-describedby="input-date-feedback"
                      ></date-picker>

                      <b-form-invalid-feedback
                        v-if="!$v.form.dateImport.required"
                        id="input-date-feedback"
                        >Vui lòng chọn ngày nhập kho</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Tổng tiền:</span>
                      </template>
                      <p class="mt-2">{{ formatMoney(totalPrice) }}</p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="3" md="3" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Kho:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <vue-autosuggest
                        class="border-radius-none mt-2"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptions"
                        @selected="onSelectedStore"
                        :limit="200"
                        @input="onInputChange"
                        :input-props="inputPropStore"
                        v-model="$v.searchStore.$model"
                        :state="validateState('searchStore')"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </div>
                      </vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateState('searchStore')"
                        id="input-store-live-feedback"
                        >Vui lòng nhập cửa hàng</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="input-group-2" label-for="input-2">
                      <label for="input-name"
                        >Điện thoại: <span class="text-danger">*</span></label
                      >
                      <vue-autosuggest
                        :suggestions="filteredOptionsCustomer"
                        @selected="onSelectedCustomer"
                        :limit="10"
                        @input="onInputChangCustomer"
                        :input-props="inputSearchCustomerProp"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="searchCustomer"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          {{ suggestion.item.suggestionName }}
                        </div>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Họ tên KH: </label>
                      <b-form-input
                        id="input-2"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="customerName"
                        required
                        placeholder="Họ tên khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Địa chỉ:</label>
                      <b-form-input
                        id="input-2"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="address"
                        required
                        placeholder="Địa chỉ khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group id="input-group-1" label-for="input-1">
                      <template>
                        <span>Ghi chú:</span>
                      </template>
                      <b-form-textarea
                        size="sm"
                        id="textarea1"
                        v-model="notes"
                        :placeholder="'Thêm nội dung ghi chú...'"
                        :rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <!-- RIGHT INPUT CONTROLS -->
              <b-col lg="3" md="3" sm="12">
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền mặt</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="cashAmount"
                        required
                        placeholder="Nhập số tiền mặt"
                        v-mask="formater.currency"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group
                      id="input-group-type"
                      :label="'Tài khoản tiền mặt'"
                      label-for="select-accountant"
                    >
                      <!-- <label for="input-name col-md-2">Loại tài khoản:</label> -->
                      <vue-autosuggest
                        class="border-radius-none mt-2"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptionsCash"
                        @selected="onSelectedCash"
                        :limit="200"
                        @input="onInputChangeCash"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn tài khoản tiền mặt',
                          style: 'border-radius:0px!important',
                        }"
                        v-model="searchCash"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </div>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền chuyển khoản</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="transferAmount"
                        required
                        placeholder="Nhập số tiền"
                        v-mask="formater.currency"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <b-form-group
                      id="input-group-type"
                      :label="'Tài khoản chuyển khoản'"
                      label-for="select-accountant"
                    >
                      <!-- <label for="input-name col-md-2">Loại tài khoản:</label> -->
                      <vue-autosuggest
                        class="border-radius-none mt-2"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptionsTransfer"
                        @selected="onSelectedTransfer"
                        :limit="200"
                        @input="onInputChangeTransfer"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn tài khoản chuyển khoản',
                          style: 'border-radius:0px!important',
                        }"
                        v-model="searchTransfer"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </div>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="col-9 pl-0 mb-4">
              <b-col lg="4" md="4" sm="12">
                <b-form-select
                  id="input-1"
                  v-model="selectTypeSearch"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                ></b-form-select>
              </b-col>
              <b-col lg="8" md="8" sm="12">
                <vue-autosuggest
                  v-model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  @selected="onSelectedProduct"
                  :limit="10"
                  @input="onInputChangProduct"
                  :input-props="inputSearchProductProp"
                  :should-render-suggestions="
                    (size, loading) =>
                      size >= 0 && !loading && searchProduct !== ''
                  "
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.productName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-col>
            </b-row>
            <span class="font-weight-bolder">Danh sách sản phẩm:</span>

            <table
              class="
                table table-bordered table-vertical-center table-hover
                col-12
                mt-4
              "
            >
              <thead>
                <tr>
                  <th scope="col" class="title-center" style="width: 15%">
                    Tên sản phẩm
                  </th>
                  <th scope="col" class="title-center" style="width: 5%">
                    Tồn
                  </th>
                  <th scope="col" class="title-center" style="width: 20%">
                    IMEI
                  </th>
                  <th scope="col" class="title-center" style="width: 10%">
                    Số lượng
                  </th>
                  <th scope="col" class="title-center" style="width: 18%">
                    Đơn giá
                  </th>
                  <th scope="col" class="title-center" style="width: 14%">
                    Thành tiền
                  </th>
                  <th scope="col" class="title-center" style="width: 13%">
                    Chiết khấu
                  </th>
                  <th scope="col" class="title-center" style="width: 5%"></th>
                </tr>
              </thead>

              <tbody v-for="item in listProductStock" :key="item.id">
                <ImportStock
                  v-bind:productItem="item"
                  v-on:cancelAdd="cancelAdd"
                  v-on:save="save"
                  v-on:deleteItem="deleteItemInArray"
                  v-on:update="updateItem"
                  v-bind:editState="true"
                />
              </tbody>
              <tr>
                <td :colspan="3" class="tdTextAlignPrice">Tổng</td>
                <td class="tdTextAlignPrice">
                  {{ formatMoney(totalQuantity) }}
                </td>
                <td></td>
                <td class="tdTextAlignPrice">{{ formatMoney(totalPrice) }}</td>
                <td class="tdTextAlignPrice">
                  {{ formatMoney(totalDiscount) }}
                </td>
                <td></td>
              </tr>
            </table>

            <div class="col-md-12" style="padding-left: 0px">
              <div class="mb-5 mt-5">
                <button
                  v-show="listProductStock.length > 0"
                  v-bind:style="btnCreate"
                  type="button"
                  class="btn btn-primary font-weight-bolder btn-sm button"
                  @click="createStock"
                >
                  Tạo phiếu nhập máy cũ
                </button>
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.input-group-append {
  cursor: pointer;
  padding-top: 5.5px;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '../../components/ImportStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { cmdUrl } from './../../../utils/apiUrl';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from './../../../utils/common';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import { TIME_TRIGGER } from './../../../utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      form: {
        dateImport: '',
      },
      isNew: true,
      dateCreate: '',
      listInventories: [],
      listProviders: [],
      listProduct: [],
      listProductSearch: [],
      count: 0,
      notes: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        // {
        //   id: 2,
        //   name: 'Tìm theo IMEI',
        // },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      timer: null,
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      payDate: null,
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      showOriginalModel: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      isSearching: false,
      inputPropStore: {
        id: 'autosuggest__input',
        placeholder: 'Chọn kho',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      tradeInId: null,
      customerName: '',
      address: '',
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      selectedClassCustomer: null,
      inputSearchCustomerProp: {
        id: 'autosuggest__input',
        placeholder: 'SĐT Khách hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },
    };
  },
  validations: {
    form: {
      dateImport: { required },
    },
    searchStore: { required },
  },
  components: {
    KTCodePreview,
    ImportStock,
    datePicker,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.dateCreate = moment().format('HH:mm DD/MM/YYYY');
    this.$v.form.dateImport.$model = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu nhập máy cũ', route: '/stocks' },
      { title: 'Tạo phiếu nhập máy cũ' },
    ]);
    this.tradeInId = this.$route.query.id;
    this.getTradeInById();
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    createStock: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let listPro = [];
      this.listProductStock.forEach((element) => {
        let item = {
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price) * 1,
          discount: unMaskPrice(element.discount) * 1,
        };
        listPro.push(item);
      });
      let data = {
        id: this.$uuid.v4(),
        tradeInId: this.tradeInId,
        storeId: this.inventorySelected,
        providerId: this.selectedClassCustomer,
        description: this.notes,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD'
            )
          : '',
        listProduct: listPro,
        cashAmount: unMaskPrice(this.cashAmount),
        cashAccountCode: this.cashAccountCode,
        transferAmount: unMaskPrice(this.transferAmount),
        transferAccountCode: this.transferAccountCode,
        payDate: this.payDate
          ? moment(this.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
      };
      //console.log(`Data: ${JSON.stringify(data)}`);
      ApiService.setHeader();
      ApiService.post('tradeIn/create-import-tradein', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                name: 'list-import-trade-in',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    cancelAdd() {
      this.listProduct.shift();
    },
    save(item) {
      this.listProduct[0] = item;
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.inventorySelected !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.inventorySelected,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        let tmpCash = [{ data: this.optionsCash[0].data }];
        this.filteredOptionsCash = tmpCash;
        let tmpTransfer = [{ data: this.optionsTransfer[0].data }];
        this.filteredOptionsTransfer = tmpTransfer;
      });
    },
    getListCustomer() {
      this.optionsCustomer[0].data = [];
      let text = this.searchCustomer;
      ApiService.setHeader();
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          customers.map((element) => {
            let cus = {
              id: element.id,
              name: element.fullName,
              phoneNo: element.phoneNo,
              suggestionName: element.phoneNo + '- ' + element.fullName,
            };
            this.optionsCustomer[0].data.push(cus);
          });
          this.filteredOptionsCustomer = [
            {
              data: this.optionsCustomer[0].data,
            },
          ];
        }
      );
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    onChangeStore() {
      this.listProductStock = [];
      this.fetchAccountants('cash');
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.onChangeStore();
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.onChangeStore();
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedClassCustomer = option.item.id;
      this.getCustomerInfoById();
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      this.debounceInput();
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [
        {
          data: filteredData,
        },
      ];
    },
    getAmount(value) {
      return _.toNumber(unMaskPrice(value));
    },
    getTradeInById: function () {
      ApiService.setHeader();
      ApiService.get(`tradeIn/${this.tradeInId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.searchCustomer = data.data.customerPhone;
            this.customerName = data.data.customerName;
            this.selectedClassCustomer = data.data.customerId;
            this.searchStore = data.data.storeName;
            this.inventorySelected = data.data.storeId;
            this.notes = data.data.note;
            this.count++;
            let quantity = 1;
            let pro = {
              id: this.count,
              name: data.data.productName,
              totalQuantityInStock: data.data.inStockQuantity,
              IMEI: data.data.imeiCode,
              proId: data.data.productId,
              price: data.data.finalBuyingPrice,
              quantity: quantity,
              discount: 0,
              totalPrice: quantity * data.data.finalBuyingPrice,
              productType: 2,
            };
            this.listProductStock.push(pro);
            this.inputSearchProductProp.disabled = data.data.productId
              ? true
              : false;
            this.searchProduct = '';
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
          this.fetchAccountants('cash');
          this.fetchAccountants('transfer');
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getCustomerInfoById() {
      ApiService.setHeader();
      ApiService.get(`customer/${this.selectedClassCustomer}`).then((data) => {
        let customerInfo = data.data.data;
        this.customerName = customerInfo.fullName;
        this.address = customerInfo.address;
      });
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      let quantity = 0;
      if (this.selectedClassProduct.productType === 1) {
        quantity = 1;
        let index = this.listProductStock.findIndex(
          (item) => item.proId === this.selectedClassProduct.productId
        );
        if (index === -1) {
          this.count++;
          let pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.quantityInStock != null
                ? this.selectedClassProduct.quantityInStock
                : 0,
            IMEI: '',
            proId: this.selectedClassProduct.productId,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            discount: 0,
            totalPrice: quantity * this.selectedClassProduct.originalPrice,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
        } else {
          this.listProductStock[index].quantity =
            this.listProductStock[index].quantity + quantity;
        }
      } else if (this.selectedClassProduct.productType === 2) {
        this.count++;
        let pro = {
          id: this.count,
          name: this.selectedClassProduct.productName,
          productCode: this.selectedClassProduct.productCode,
          barCode: this.selectedClassProduct.barCode,
          totalQuantityInStock:
            this.selectedClassProduct.quantityInStock != null
              ? this.selectedClassProduct.quantityInStock
              : 0,
          IMEI: '',
          proId: this.selectedClassProduct.productId,
          price: this.selectedClassProduct.originalPrice,
          quantity: quantity,
          discount: 0,
          totalPrice: quantity * this.selectedClassProduct.originalPrice,
          productType: this.selectedClassProduct.productType,
        };
        this.listProductStock.unshift(pro);
      } else if (this.selectedClassProduct.productType === 3) {
        this.makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
      }

      this.searchProduct = '';
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei();
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.setHeader();
      ApiService.get(
        `productSearch?storeId=${this.inventorySelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
  },
};
</script>
